var site = site || {};

site.changeLocale = function (country, lang) {
    var postfixes = {};
    postfixes[lang] = lang;
    postfixes[country] = country.toLowerCase();

    var locale_cookie = lang + '_' + country.toUpperCase();
    generic.cookie('LOCALE', locale_cookie, {path : '/'});
};

jQuery.fn.cleanWhitespace = function() {
  textNodes = this.contents().filter(
    function() { return (this.nodeType == 3 && !/\S/.test(this.nodeValue)); })
    .remove();
  return this;
}

